<template>
<div>
    <div class="container-fluid">
        <div class="col-md-12">
            <!-- <br> -->
            <form id="location" @submit.prevent="saveLocation">
                <div class="card card-secondary">
                    <div class="card-header">
                        <h3 class="card-title">Add Locations</h3>

                        <div class="card-tools">
                            <button type="button" class="btn btn-tool" data-card-widget="collapse" title="Collapse">
                                <i class="fas fa-minus"></i>
                            </button>
                        </div>
                    </div>

                    <div class="card-body">
                        <div class="row">
                            <div class="col-md 5">
                                <div class="form-group">
                                    Location
                                    <div class="row">
                                        <div class="col-2">
                                            <input type="text" id="locaId" class="form-control" v-model="setLocaId" required />
                                        </div>
                                        <div class="col-10">
                                            <input type="text" id="locaName" class="form-control" v-model="setLocaName" required />
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="address">Address</label>
                                    <textarea type="text" id="address" class="form-control" v-model="setAddress" required></textarea>
                                </div>

                                <div class="row">
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            Phone no
                                            <input type="number" id="phoneNo" class="form-control" v-model="setPhoneNo" required />
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            E-Mail
                                            <input type="text" id="email" class="form-control" v-model="setEmail" />
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            Start Date
                                            <input type="date" id="startedDate" class="form-control" v-model="setStartedDate" required />
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            Providing
                                            <div class="row">
                                                <div class="col-6">
                                                    <div class="form-check">
                                                        <input class="form-check-input" type="checkbox" id="points" v-model="setPoints" />
                                                        <label class="form-check-label">Points</label>
                                                    </div>
                                                    <div class="form-check">
                                                        <input class="form-check-input" type="checkbox" id="discount" v-model="setDiscount" />
                                                        <label class="form-check-label">Discounts</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div class="col-md-7">
                                <div class="card">
                                    <div class="card-header">
                                        <h3 class="card-title">Location Details</h3>
                                    </div>
                                    <!-- /.card-header -->
                                    <div class="card-body table-responsive">
                                        <table class="table table-striped projects table-codensed" width="100%">
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Location</th>
                                                    <th>Address</th>
                                                    <th>Phone No</th>
                                                    <th>Status</th>
                                                    <th class="text-right">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody >
                                                <tr v-for="location in locations" :key="location.LocaCode">
                                                    <td>{{ location.LocaCode }}</td>
                                                    <td>{{ location.LocaName }}</td>
                                                    <td>{{ location.Ad }}</td>
                                                    <td>{{ location.PhoneNo }}</td>
                                                    <td>Active</td>
                                                    <td class="text-right">
                                                        <button @click="selectLocation(location.LocaCode)" type="button" class="btn btn-primary btn-xs mr-1" value="Search">
                                                            <i class="fa fa-edit"></i>
                                                        </button>
                                                        <button type="button" class="btn btn-danger btn-xs mr-1" value="Search">
                                                            <i class="fa fa-trash"></i>
                                                        </button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="row"> -->
                        <div class="col-md-12  text-right">
                            <div class="form-group">
                                <button @click="clearInputs" type="button" class="btn btn-primary float-right mr-2">
                                    Clear
                                </button>
                            </div>
                            <div class="form-group">
                                <button type="button" @click="updateLocation" class="btn btn-warning float-right mr-2">
                                    Edit
                                </button>
                            </div>
                            <div class="form-group">
                                <button type="submit" v-bind:disabled="flag" class="btn btn-success float-right mr-2">
                                    Add
                                </button>
                            </div>
                        </div>
                        <!-- </div> -->
                    </div>
                    <!-- /.card-body -->
                </div>
            </form>
            <!-- /.card -->
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: "addLocations",
    data() {
        return {
            setLocaId: "",
            setLocaName: "",
            setAddress: "",
            setPhoneNo: "",
            setEmail: "",
            setStartedDate: "",
            setPoints: false,
            setDiscount: false,
            locationData: [],
            locations: [],
            flag: false,
            userName: null,
        };
    },
    methods: {
        saveLocation() {
            var msg = "";
            this.locationData = {
                LocaCode: this.setLocaId,
                LocaName: this.setLocaName,
                Ad: this.setAddress,
                PhoneNo: this.setPhoneNo,
                Email: this.setEmail,
                StartDate: this.setStartedDate,
                Points: this.setPoints,
                Discount: this.setDiscount,
                InsertUser: this.userName,
            };

            this.$swal.fire({
                title: "Are you sure you want to Add Location",
                text: "by Confirming New Location will be Created",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, Add it!",
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$store.dispatch("postLocation", this.locationData).then((res) => {
                        if (res.data.Code == 102) {
                            msg = res.data.Code + " " + res.data.Info;
                        } else {
                            msg = "Location add Success";
                        }
                        this.toastMessage(msg, "success");
                        this.fetchLocations();
                    });
                }
            });

        },

        updateLocation() {
            var msg = "";
            this.locationData = {
                LocaCode: this.setLocaId,
                LocaName: this.setLocaName,
                Ad: this.setAddress,
                PhoneNo: this.setPhoneNo,
                Email: this.setEmail,
                StartDate: this.setStartedDate,
                Points: this.setPoints,
                Discount: this.setDiscount,
                InsertUser: this.userName,
            };
            this.$confirm(
                "Are you sure you want to update Location?",
                "Location",
                "question"
            ).then(() => {
                this.$store
                    .dispatch("updateLocation", this.locationData)
                    .then((res) => {
                        if (res.data.Code == 102) {
                            msg = res.data.Code + " " + res.data.Info;
                        } else {
                            msg = "Location update Success";
                        }
                        this.toastMessage(msg, "success");
                        this.fetchLocations();
                    });
                this.clearInputs();
            });
        },

        fetchLocations() {
            this.$loading(true);
            this.$store.dispatch("common/fetchLocations", null, {
                root: true
            }).then(() => {
                this.locations = this.$store.state.common.locations;
            });
            this.flag = false;

            this.$loading(false);
        },
        selectLocation(id) {
            var obj = this.locations.filter((x) => x.LocaCode === id);
            if (obj.length > 0) {
                this.flag = true;
                this.setLocaId = obj[0].LocaCode;
                this.setLocaName = obj[0].LocaName;
                this.setAddress = obj[0].Ad;
                this.setPhoneNo = obj[0].PhoneNo;
                this.setEmail = obj[0].Email;
                this.setStartedDate = obj[0].StartDate;
                this.setPoints = obj[0].Points;
                this.setDiscount = obj[0].Discount;
            }
        },

        toastMessage(text, type) {
            this.$swal.fire({
                position: "center",
                icon: type,
                title: text,
                showConfirmButton: false,
                timer: 1500,
            });
        },

        clearInputs() {
            this.flag = true;
            this.setLocaId = ""
            this.setLocaName = ""
            this.setAddress = ""
            this.setPhoneNo = ""
            this.setEmail = ""
            this.setStartedDate = null
            this.setPoints = ""
            this.setDiscount = ""
            this.flag = false;
        }
    },

    created() {
        this.fetchLocations();
    },
    beforeMount() {
        this.userName = localStorage.getItem("user");
    },
};
</script>
