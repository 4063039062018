import axios from "axios";

const options = {
  headers: {
    "Content-Type": "application/json",
    Authorization: "bearer " + localStorage.getItem("token") || null,
  },
};

const state = {
  optionCG: null,
  optionREL: null,
  customers: [],
};

const getters = {
  optionCG: (state) => state.optionCG,
  optionREL: (state) => state.optionREL,
  customers: (state) => state.customers,
};

const mutations = {
  SET_optionCG(state, optionCG) {
    state.optionCG = optionCG;
  },
  SET_optionREL(state, optionREL) {
    state.optionREL = optionREL;
  },

  searchCustomer(state, customer) {
    axios
      .get("CRM/SearchCus/" + customer, options)
      .then((r) => r.data)
      .then((data) => {
        state.customers = data.ResData.searchRes;
      })
      .catch((err) => {
        console.log(err);
      });
  },

  UpdateCustomer(state, payload) {
    state.customers = payload;
  },
};

const actions = {
  getCusGroup({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .post("CRM/getoptions/CG", {}, options)
        .then(({ data }) => {
          commit("SET_optionCG", data.CommonResult.options);
          resolve(data);
        })
        .catch((err) => {
          // localStorage.removeItem('token')
          reject(err);
        });
    });
  },

  getRelegions({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          "CRM/getoptions/REL",
          {},

          options
        )
        .then(({ data }) => {
          commit("SET_optionREL", data.CommonResult.options);
          resolve(data);
        })
        .catch((err) => {
          // localStorage.removeItem('token')
          reject(err);
        });
    });
  },

  insertCustomer({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          "CRM/CreateCustomer",
          {
            Code: payload.Code,
            Title: payload.Title,
            FirstName: payload.FirstName,
            LastName: payload.LastName,
            Address1: payload.Address1,
            Mobile: payload.Mobile,
            NIC: payload.NIC,
            CardNo: payload.CardNo,
            issuedate: payload.issuedate,
            CustomerGroup: payload.CustomerGroup,
            Religion: payload.Religion,
            Gender: payload.Gender,
            Birthday: payload.Birthday,
            New_Year: payload.New_Year,
            Vesak: payload.Vesak,
            X_Mas: payload.X_Mas,
            Thaipongal: payload.Thaipongal,
            Deepawali: payload.Deepawali,
            Ramazan: payload.Ramazan,
            WomenDay: payload.WomenDay,
            Loca:payload.loca,
            Email:payload.email,
            IsApproved:payload.IsApproved,
            User: localStorage.getItem("user"),
          },

          options
        )
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          // localStorage.removeItem('token')
          reject(err);
        });
    });
  },

  
  fetchCustomer({ commit }) {
    axios.get("CRM/SearchCus", options).then((res) => {
      state.customers = res.ResData.DataRet;
      commit("UpdateCustomer", res.ResData.DataRet);
    });
  },
  retCustomer({ commit }) {
    // axios.get("CRM/SearchCus", options)
    //     .then(r => r.data).then(x => {
    //         console.log(x.ResData.searchRes)
    //         state.customers = x.ResData.searchRes
    //     });
  },

  readCustomer({ commit }, customer) {
    return new Promise((resolve, reject) => {
      axios
        .get("CRM/Dataret/Cus/" + customer, options)
        .then(({ data }) => {
          state.customers = data.ResData.DataRet;
          console
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchSelectedCustomerPoints({ commit }, customerCode) {
    return new Promise((resolve, reject) => {
      axios
        .get("CRM/Dataret/Cus/" + customerCode, options)
        .then(({ data }) => {
          
          state.customers = data.ResData.searchRes;
          commit("UpdateCustomer", data.ResData.searchRes);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },



  fetchNonApprovedCustomers({ commit },customerCode) {
    console.log(customerCode)
    return new Promise((resolve, reject) => {
      axios
      .get("CRM/NonApproved/" + customerCode, options)
      .then((r) => r.data)
      .then((data) => {

        commit("UPDATE_CUSTOMER",data.ResData.searchRes);
        resolve(data)
        // state.customers = data.ResData.searchRes;
        //setCust(sta)
      })
      .catch((err) => {
        reject(err)
        console.log(err);
      });
    });
  },
};

export default { state, getters, mutations, actions };
