<template>
<div>
    <div class="preloader flex-column justify-content-center align-items-center" style="height: 100%">
        <img class="animation__shake" src="../assets/logo.png" alt="AdminLTELogo" height="220" width="300">
    </div>
    <!-- Navbar -->
    <nav class="main-header navbar navbar-expand navbar-white navbar-light">
        <!-- Left navbar links -->
        <ul class="navbar-nav">
            <li class="nav-item">
                <a class="nav-link" data-widget="pushmenu" role="button">
                    <i class="fas fa-bars"></i>
                </a>
            </li>
            <li class="nav-item d-none d-sm-inline-block">
                <router-link to="/home" class="nav-link">Home</router-link>
            </li>

        </ul>

        <!-- Right navbar links -->
        <ul class="navbar-nav ml-auto">
            <li class="nav-item d-none d-sm-inline-block">
                <a class="nav-link" v-on:click="logout">Logout</a>
            </li>
        </ul>
    </nav>
    <!-- /.navbar -->

    <!-- Main Sidebar Container -->
    <aside class="main-sidebar sidebar-dark-primary elevation-4">
        <!-- Brand Logo -->
        <router-link to="/home" class="brand-link">
            <img src="../assets/AdminLTELogo.png" alt="AdminLTE Logo" class="brand-image img-circle elevation-3" style="opacity: .8">
            <span class="brand-text font-weight-light">
                <b>NS Handy</b> CRM</span>
        </router-link>
        <!-- Sidebar -->
        <div class="sidebar os-host os-theme-light os-host-overflow os-host-overflow-y os-host-resize-disabled os-host-scrollbar-horizontal-hidden os-host-transition">
            <div class="user-panel mt-3 pb-3 mb-3 d-flex">
                <div class="image">
                    <img src="../assets/avatar.png" class="img-circle" alt="User Image">
                </div>
                <div class="info">
                    <a class="d-block">
                        <center>Sign In By {{ F_name }}</center>
                    </a>
                </div>
            </div>

            <div class="form-inline">
                <div class="input-group" data-widget="sidebar-search">
                    <input class="form-control form-control-sidebar" type="search" placeholder="Search" aria-label="Search" />
                    <div class="input-group-append">
                        <button class="btn btn-sidebar">
                            <i class="fas fa-search fa-fw"></i>
                        </button>
                    </div>
                </div>
            </div>

            <nav class="mt-2">
                <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="true">
                    <li class="nav-item">
                        <a href="" class="nav-link">
                            <i class="nav-icon fas fa-tachometer-alt"></i>
                            <p>
                                Master file
                                <i class="right fas fa-angle-left"></i>
                            </p>
                        </a>
                        <ul class="nav nav-treeview">
                            <!-- <li class="nav-item">
                                <router-link to="/company" class="nav-link">
                                    <i class="far fa-circle nav-icon"></i>
                                    <p>Company Profile</p>
                                </router-link>
                            </li> -->
                            <li class="nav-item">
                                <router-link to="/regcustomer" class="nav-link">
                                    <i class="far fa-circle nav-icon"></i>
                                    <p>Customer Registration</p>
                                </router-link>
                            </li>
                        </ul>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link">
                            <i class="nav-icon fas fa-edit"></i>
                            <p>
                                Transaction
                                <i class="right fas fa-angle-left"></i>
                            </p>
                        </a>
                        <ul class="nav nav-treeview">
                            <li v-if="userName=='ADMIN' || 'NSPOS'" class="nav-item">
                                <router-link to="/points" class="nav-link">
                                    <i class="far fa-circle nav-icon"></i>
                                    <p>Points Update</p>
                                </router-link>
                            </li>
                            <li class="nav-item">
                                <router-link to="/sendsms" class="nav-link">
                                    <i class="far fa-circle nav-icon"></i>
                                    <p>Send SMS</p>
                                </router-link>
                            </li>
                        </ul>
                    </li>
                    <li class="nav-item">
                        <a href="" class="nav-link">
                            <i class="nav-icon fas fa-tachometer-alt"></i>
                            <p>
                                Reports
                                <i class="right fas fa-angle-left"></i>
                            </p>
                        </a>
                        <ul class="nav nav-treeview">
                            <li class="nav-item">
                                <router-link to="/inqCustDetails" class="nav-link">
                                    <i class="far fa-circle nav-icon"></i>
                                    <p>Customer Details Report</p>
                                </router-link>
                            </li>

                            <li class="nav-item">
                                <router-link to="/customerStatement" class="nav-link">
                                    <i class="far fa-circle nav-icon"></i>
                                    <p>Customer Statement</p>
                                </router-link>
                            </li>
                            <li class="nav-item">
                                <router-link to="/customerBalance" class="nav-link">
                                    <i class="far fa-circle nav-icon"></i>
                                    <p>Customer Balance</p>
                                </router-link>
                            </li>
                        </ul>
                    </li>
                    <li v-if="userName=='ADMIN'" class="nav-item">
                        <a class="nav-link">
                            <i class="nav-icon fas fa-edit"></i>
                            <p>
                                System Admin
                                <i class="right fas fa-angle-left"></i>
                            </p>
                        </a>
                        <ul class="nav nav-treeview">
                            <li class="nav-item">
                                <router-link to="/settings" class="nav-link">
                                    <i class="far fa-circle nav-icon"></i>
                                    <p>Settings</p>
                                </router-link>
                            </li>
                        </ul>
                    </li>
                </ul>
            </nav>
        </div>
        <div class="
            os-scrollbar
            os-scrollbar-horizontal
            os-scrollbar-unusable
            os-scrollbar-auto-hidden
          ">
            <div class="os-scrollbar-track">
                <div class="os-scrollbar-handle" style="width: 100%; transform: translate(0px, 0px)"></div>
            </div>
        </div>
        <div class="os-scrollbar os-scrollbar-vertical os-scrollbar-auto-hidden">
            <div class="os-scrollbar-track">
                <div class="os-scrollbar-handle" style="height: 62.334%; transform: translate(0px, 73.7403px)"></div>
            </div>
        </div>
        <div class="os-scrollbar-corner"></div>

    </aside>
    <div class="content-wrapper">
        <transition :duration="600" name="fade" mode="out-in">
            <router-view />
        </transition>
        <!-- <router-view>

        </router-view> -->
    </div>
    <aside class="control-sidebar control-sidebar-dark">
        <div class="p-3 control-sidebar-content" style="">
            <h5>Customize NS Handy CRM</h5>
            <hr class="mb-2">
            <div class="mb-4">
                <div class="custom-control custom-switch">
                    <input @change="changeTheme" v-model="darkMode" type="checkbox" class="custom-control-input" id="customSwitch1" checked>
                    <label class="custom-control-label" for="customSwitch1">{{theme}}</label>
                </div>
            </div>
        </div>
    </aside>
</div>
</template>

<script>
import {
    mapGetters
} from "vuex";
import dashBoard from './Home/Dashboard.vue'
import addCustomerGroup from "./admin/AddCustomerGroup.vue"
export default {
    components: {
        dashBoard,
        addCustomerGroup,
    },
    data() {
        return {
            F_name: "",
            darkMode: false,
            theme: "",
            userName: "",
        };
    },
    methods: {
        logout() {
            this.$confirm(
                "Are you sure you want to logout?",
                "Logout",
                "question"
            ).then(() => {
                localStorage.setItem("token", null);
                localStorage.setItem("user", null);
                localStorage.setItem("login", false);

                console.log(localStorage.getItem("token"));
                console.log(localStorage.getItem("login"));
                this.$router.push("/");
            });
        },
        // toggleBodyClass(addRemoveClass, className) {
        //     const el = document.body;

        //     if (addRemoveClass === 'addClass') {
        //         el.classList.add(className);
        //     } else {
        //         el.classList.remove(className);
        //     }
        // },
        changeTheme() {
            console.log(this.darkMode);
            const el = document.body;

            if (this.darkMode === true) {
                el.classList.add('dark-mode');
                this.theme = "Dark"
            } else {
                el.classList.remove('dark-mode');
                this.theme = "Light"
            }
        }
    },
    beforeMount() {
        this.F_name = localStorage.getItem("user");
        this.userName = localStorage.getItem("user");
        console.log(this.userName)
    },

    computed: {
        ...mapGetters(["user"]),
    },
    mounted() {
        console.log(localStorage.getItem("login"));

        if (localStorage.getItem("login") == "false" || localStorage.getItem("token") == null) {
            console.log(localStorage.getItem("login") + "ttttt");
            this.$router.push("/");
        }

        this.changeTheme();
    },

    destroyed() {
        this.changeTheme();
    }
};
</script>
