import axios from "axios";

const options = {
  headers: {
    "Content-Type": "application/json",
    Authorization: "bearer " + localStorage.getItem("token") || null,
  },
};
const namespaced = true;
const state = {
  //   optionCG: null,
  //   optionREL: null,
  //   customers: [],
  locations: [],
  groups: [],
  customersData: [],
};

const getters = {
  customersData: (state) => state.customersData,
  locations: (state) => state.locations,
  groups: (state) => state.groups,
};
namespaced;
const mutations = {
  SET_LOCATION(state, data) {
    state.locations = data;
  },
  SET_GROUPS(state, data) {
    state.groups = data;
  },
  SET_CUSTOMERS(state, data) {
    state.customersData = data;
  },
};

const actions = {
  fetchLocations({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("CRM/Locations", options)
        .then(({ data }) => {
          commit("SET_LOCATION", data.ResData.DataRet);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchCustomerGroups({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("CRM/Groups", options)
        .then(({ data }) => {
          if (data.statusCode == 200) {
            commit("SET_GROUPS", data.data);
            // console.log(data.data)
          }
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchCustomers({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("CRM/getCustomerList", options)
        .then(({ data }) => {
          commit("SET_CUSTOMERS", data.data);
          console.log(data)
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

export default { namespaced, state, getters, mutations, actions };
