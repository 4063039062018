import axios from "axios";

const options = {
  headers: {
    "Content-Type": "application/json",
    Authorization: "bearer " + localStorage.getItem("token") || null,
  },
};

const state = {
  customerGroups: [],
  locations: [],
  customerDetails:[]
};

const getters = {
  customerGroups: (state) => state.customerGroups,
  location: (state) => state.location,
};

const mutations = {
  SET_CUSTOMER_GROUPS(state, data) {
    state.customerGroups = data;
  },
  SET_LOCATIONS(state, data) {
    state.locations = data;
  },
  SET_CUSTOMER_DETAILS(state, data) {
    state.customerDetails = data;
  },

  searchCustomer(state, customer) {
    axios
      .get("CRM/SearchCus/" + customer, options)
      .then((r) => r.data)
      .then((data) => {
        state.customers = data.ResData.searchRes;
      })
      .catch((err) => {
        console.log(err);
      });
  },
};

const actions = {
  fetchCustomerGroups({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .post("CRM/getoptions/CG", {},options)
        .then(({ data }) => {
          commit("SET_CUSTOMER_GROUPS", data.CommonResult.options);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchLocation({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .post("CRM/getoptions/Loca", {},options)
        .then(({ data }) => {
          commit("SET_LOCATIONS", data.CommonResult.options);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchCustomerDetails({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("CRM/fetchCustomers", payload, options)
        .then(({ data }) => {
          commit("SET_CUSTOMER_DETAILS", data.data);
          // console.log(data)
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

export default { state, getters, mutations, actions };
