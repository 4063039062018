<template>
<div>
    <section class="content-header">
        <!-- <div class="container-fluid"> -->
            <!-- <div class="row mb-2"> -->
                <!-- <div class="col-sm-6">
                    <h1>Project Add</h1>
                </div> -->
                <!-- <div class="col-sm-6">
                    <ol class="breadcrumb float-sm-right">
                        <li class="breadcrumb-item"><a href="#">Home</a></li>
                        <li class="breadcrumb-item active">Project Add</li>
                    </ol>
                </div> -->
            <!-- </div> -->
        <!-- </div> -->
    </section>
    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12">
                    <div class="card card-primary">
                        <div class="card-header">
                            <h3 class="card-title">System Settings</h3>

                            <div class="card-tools">
                                <button type="button" class="btn btn-tool" data-card-widget="collapse" title="Collapse">
                                    <i class="fas fa-minus"></i>
                                </button>
                            </div>
                        </div>
                        <div class="card-body">
                            <ul class="nav nav-tabs" role="tablist">
                                <li class="nav-item">
                                    <a class="nav-link active" data-toggle="tab" href="#locaTab" role="tab">Location Master</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" data-toggle="tab" href="#tabs-2" role="tab">Customer Groups</a>
                                </li>
                                <!-- <li class="nav-item">
                                    <a class="nav-link" data-toggle="tab" href="#tabs-3" role="tab">Card Types</a>
                                </li> -->
                            </ul><!-- Tab panes -->
                            <div class="tab-content">
                                <div class="tab-pane active" id="locaTab" role="tabpanel">
                                    <addLocations />
                                </div>
                                <div class="tab-pane" id="tabs-2" role="tabpanel">
                                    <addCustomerGroup />
                                </div>
                                <!-- <div class="tab-pane" id="tabs-3" role="tabpanel">
                                    <p>Third Panel</p>
                                </div> -->
                            </div>

                        </div>
                        <!-- /.card-body -->
                    </div>
                    <!-- /.card -->
                </div>

            </div>
            <!-- <div class="row">
                <div class="col-12 ">
                    <a href="#" class="btn btn-secondary float-right">Cancel</a>
                    <input type="submit" value="Create new Porject" class="btn btn-success float-right">
                </div>
            </div> -->
        </div>
    </section>
</div>
</template>

<script>
import addCustomerGroup from "./AddCustomerGroup.vue"
import addLocations from "./AddLocations.vue"
export default {
    components: {
        addCustomerGroup,
        addLocations
    },
}
</script>
