// import axios from "axios";


// const options = {
//   headers: {
//     "Content-Type": "application/json",
//     Authorization: "bearer " + localStorage.getItem("token") || null,
//   },
// };

// const state = {
//   state: {
//     customers: [],
//     srCustomers: []
//   },
// };

// const getters = {

//   customer: state => {
//     return state.customers;
//   }
// };

// const actions = {


  
//   sendSMSCall({ commit }, pyl) {
//     return new Promise((resolve, reject) => {

    

//   // var   clsSMSsend = JSON.parse(pyl);

//       axios.post(
//         "CRM/SMSsend", pyl,
//         options,
//       ).then(({ data }) => {
//         console.log(data);
//         resolve(data);
//       }
//       ).catch(err => {
//         reject(err)
   
//       }
//       );
//     })

//   },
//   // getFilterGroupCus(state, group) {
//   //   axios
//   //     .get("api/CRM/Groupcus/"+group, options)
//   //     .then((r) => r.data)
//   //     .then((data) => {
//   //       state.customers = [];
//   //       state.customers = data.ResData.searchRes;
//   //       console.log(state.customers);
//   //       setCust(sta)
//   //     })
//   //     .catch((err) => {
//   //       console.log(err);
//   //     });
//   // },
//   // getFilterGroupCus(state, group) {
//   //   axios
//   //     .get("api/CRM/Groupcus/"+group, options)
//   //     .then((r) => r.data)
//   //     .then((data) => {
//   //       state.customers = [];
//   //       state.customers = data.ResData.searchRes;
//   //       console.log(state.customers);
//   //       setCust(sta)
//   //     })
//   //     .catch((err) => {
//   //       console.log(err);
//   //     });
//   // },

// //   updateCustomer(state, payload) {
// //     state.customers = payload
// // },

// getFilterGroupCus({ commit },group) {
//   axios
//       .get("CRM/Groupcus/" + group, options)
//       .then((r) => r.data)
//       .then((data) => {
//        commit('FETCH_CUSTOMER', data.ResData.DataRet)

//       })
//       .catch((err) => {
//         console.log(err);
//       });
// }
  
// };

// const mutations = {

//   FILL_CUSTOMERS(state, payload) {
//     state.customers = payload;
//   },
//   FETCH_CUSTOMER(state, payload) {
//     state.customers = payload
//   },

//   getSMSCustomerFilter(state, customer) {
//     axios
//       .get("CRM/SearchCus/" + customer, options)
//       .then((r) => r.data)
//       .then((data) => {
//         state.srCustomers = [];
//         state.srCustomers = data.ResData.searchRes;
//         console.log(data.ResData.searchRes);
//       })
//       .catch((err) => {
//         console.log(err);
//       });
//   },
//   // getFilterGroupCus(state, group) {
//   //   axios
//   //     .get("CRM/Groupcus/" + group, options)
//   //     .then((r) => r.data)
//   //     .then((data) => {
//   //       state.customers =[];
//   //       state.customers = data.ResData.DataRet;
//   // // this.updateCustomer(state, data.ResData.DataRet);
//   // // commit('updateCustomer',data.ResData.DataRet)

//   //       // console.log(data)

//   //     })
//   //     .catch((err) => {
//   //       console.log(err);
//   //     });
//   // },


// };

// export default { state, getters, mutations, actions };


import axios from "axios";


const options = {
  headers: {
    "Content-Type": "application/json",
    Authorization: "bearer " + localStorage.getItem("token") || null,
  },
};

const state = {
  state: {
    customers: [],
    srCustomers: []
  },
};

const getters = {

  dataCustomer: state => { return state.customers },
};

const actions = {



  sendSMSCall({ commit }, pyl) {
    return new Promise((resolve, reject) => {



      // var   clsSMSsend = JSON.parse(pyl);

      axios.post(
        "SendSms", pyl,
        options,
      ).then(({ data }) => {
        console.log(data);
        resolve(data);
      }
      ).catch(err => {
        reject(err)

      }
      );
    })

  },





  async getFilterGroupCus ({ commit }, group) {

    // return new Promise((resolve, reject) => {
    //  await axios.get("CRM/Groupcus/" + group, options
    //   ).then(({ data }) => {
    //     commit('FETCH_CUSTOMER', data.ResData.DataRet)
    //     resolve(data);
    //   }
    //   ).catch(err => {
    //     reject(err)

    //   }
    //   );
    // })








    await axios
      .get("CRM/Groupcus/" + group, options)
      .then((r) => r.data)
      .then((data) => {
        // console.log(data.ResData.DataRet)
        commit('FETCH_CUSTOMER', data.ResData.DataRet)

      })
      .catch((err) => {
        console.log(err);
      });
  }

};

const mutations = {

  FILL_CUSTOMERS(state, payload) {
    state.customers = payload;
  },
  FETCH_CUSTOMER(state, payload) {
    state.customers = payload
    console.log(state.customers)
  },

  getSMSCustomerFilter(state, customer) {
    axios
      .get("CRM/SearchCus/" + customer, options)
      .then((r) => r.data)
      .then((data) => {
        state.srCustomers = [];
        state.srCustomers = data.ResData.searchRes;
        console.log(data.ResData.searchRes);
      })
      .catch((err) => {
        console.log(err);
      });
  },

};

export default { state, getters, mutations, actions };