<template>
<div>
    <div class="col-md-12">
        <form @submit.prevent="saveCustomerGroup">
            <div class="card card-secondary">
                <div class="card-header">
                    <h3 class="card-title">Add Customer Groups</h3>

                    <div class="card-tools">
                        <button type="button" class="btn btn-tool" data-card-widget="collapse" title="Collapse">
                            <i class="fas fa-minus"></i>
                        </button>
                    </div>
                </div>
                <div class="card-body">

                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="inputEstimatedBudget">Group Id</label>
                                <input type="number" id="inputEstimatedBudget" v-model="setGroupId" class="form-control">
                            </div>
                            <div class="form-group">
                                <label for="inputSpentBudget">Description</label>
                                <input type="text" id="inputSpentBudget" v-model="setGroupName" class="form-control">
                            </div>
                            <div class="form-group">
                                <label>Point Rate <small>(ex: 1 for 100/=)</small></label>
                                <input type="text" id="setPointRate" v-model="setPointRate" class="form-control">
                            </div>

                        </div>
                        <div class="col-md-6">
                            <table class="table table-striped projects table-codensed" width="100%">
                                <thead>
                                    <tr>
                                        <th>
                                            Group Id
                                        </th>
                                        <th>
                                            Group Name
                                        </th>
                                        <th>Point Rate</th>

                                        <th class="text-right">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="group in CustomerGroups" :key="group.id">
                                        <td>
                                            {{group.id}}
                                        </td>
                                        <td>
                                            {{group.GroupName}}
                                        </td>
                                        <td>{{group.PointsRate}}</td>

                                        <td class="text-right">
                                            <button type="button" class="btn btn-primary btn-xs mr-2">
                                                <i class="fas fa-folder">
                                                </i>
                                            </button>
                                            <button @click="selectGroup(group.id)" type="button" class="btn btn-info btn-xs mr-2">
                                                <i class="fas fa-pencil-alt">
                                                </i>
                                            </button>
                                            <button type="button" class="btn btn-danger btn-xs">
                                                <i class="fas fa-trash">
                                                </i>

                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                        </div>

                    </div>
                    <div class="row float-right">
                        <div class="form-group">
                            <input type="submit" value="Save" class="btn btn-success float-right mr-2" />
                        </div>
                        <div class="form-group">
                            <input type="button" value="Clear" class="btn btn-primary float-right mr-2" />
                        </div>
                    </div>

                </div>
                <!-- /.card-body -->
            </div>
            <!-- /.card -->
        </form>
    </div>
</div>
</template>

<script>
export default {
    name: "addCustomerGroup",
    data() {
        return {
            CustGroupData: "",
            setGroupId: "",
            setGroupName: "",
            setPointRate: 0,
            CustGroupData: [],
            CustomerGroups: []
        }
    },

    methods: {
        saveCustomerGroup() {
            this.CustGroupData = {
                groupId: this.setGroupId,
                groupName: this.setGroupName,
                pointsRate: this.setPointRate,
                insertUser: localStorage.getItem("user").toUpperCase()
            };
            this.$store.dispatch("postCustomerGroup", this.CustGroupData).then((res) => {

                if (res.data.Code == 102) {
                    this.$swal.fire({
                        position: "center",
                        icon: "info",
                        title: res.data.Info,
                        showConfirmButton: false,
                        timer: 1500,
                    });
                } else if (res.data.Code == 200) {
                    this.$swal.fire({
                        position: "center",
                        icon: "success",
                        title: "Customer Group Added Success",
                        showConfirmButton: false,
                        timer: 1500,
                    });
                    this.CustomerGroups = this.$store.state.settings.CustomerGroupList;
                    this.fetchCustomerGroups();
                }

            });
        },

        fetchCustomerGroups() {
            this.$loading(true);
            this.$store.dispatch("common/fetchCustomerGroups", null, {
                root: true
            }).then(() => {
                this.CustomerGroups = this.$store.state.common.groups;
            });
            this.flag = false;

            this.$loading(false);
        },
        selectGroup(id) {
            var obj = this.CustomerGroups.filter((x) => x.id === id);
            if (obj.length > 0) {
                this.flag = true;
                this.setGroupId = obj[0].id;
                this.setGroupName = obj[0].GroupName;
                this.setPointRate = obj[0].PointsRate;

            }
        },
    },
    created() {
        this.fetchCustomerGroups();
    }
}
</script>
