<template>
  <div>
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-12">
            <h1 class="m-0 text-dark">Company Profile</h1>
          </div>
        </div>
      </div>
    </div>
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12 col-sm-6 col-md-12">
            <!-- Default box -->
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">Enter Details</h3>
              </div>
              <div class="card-body">
                <form @submit.prevent="submit">
                  <div class="row">
                    <div class="col-6">
                      <div class="form-group">
                        Location
                        <div class="row">
                          <div class="col-2">
                            <input
                              type="text"
                              id="inputName"
                              class="form-control"
                              v-model="Loca_id"
                            />
                          </div>

                          <div class="col-10">
                            <input
                              type="text"
                              id="inputName"
                              class="form-control"
                              v-model="Loca_Name"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="form-group">
                        <!-- <label for="inputName">Product Name</label> -->
                        Address
                        <input
                          type="text"
                          id="inputName"
                          class="form-control"
                          v-model="Ad"
                        />
                      </div>
                      <div class="form-group">
                        <!-- <label for="inputName">Product Name</label> -->
                        Address 1
                        <input
                          type="text"
                          id="inputName"
                          class="form-control"
                          v-model="Address1"
                        />
                      </div>
                      <div class="form-group">
                        <!-- <label for="inputName">Product Name</label> -->
                        Address 2
                        <input
                          type="text"
                          id="inputName"
                          class="form-control"
                          v-model="Address2"
                        />
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group">
                        <!-- <label for="inputName">Product Name</label> -->
                        Phone no
                        <input
                          type="text"
                          id="inputName"
                          class="form-control"
                          v-model="PhoneNo"
                        />
                      </div>

                      <div class="form-group">
                        Fax
                        <input
                          type="text"
                          id="inputName"
                          class="form-control"
                          v-model="Fax"
                        />
                      </div>
                      <div class="form-group">
                        E-Mail
                        <input
                          type="text"
                          id="inputName"
                          class="form-control"
                          v-model="Email"
                        />
                      </div>
                      <div class="form-group">
                        Start Date
                        <input
                          type="date"
                          id="datefrom"
                          class="form-control"
                          v-model="StartDate"
                        />
                      </div>
                      <div class="form-group">
                        <!-- <label for="inputName">Product Name</label> -->
                        Providing
                        <div class="row">
                          <div class="col-6">
                            <div class="form-check">
                              <input class="form-check-input" type="checkbox" />
                              <label class="form-check-label">Points</label>
                            </div>
                            <div class="form-check">
                              <input class="form-check-input" type="checkbox" />
                              <label class="form-check-label">Discounts</label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="form-group">
                      <input
                        type="submit"
                        value="Save"
                        class="btn btn-success float-right mr-2"
                      />
                    </div>
                    <div class="form-group">
                      <input
                        type="button"
                        value="Clear"
                        class="btn btn-primary float-right mr-2"
                      />
                    </div>
                  </div>
                </form>
              </div>
              <!-- /.card-body -->
              <div class="card-footer">
                <p>* Marked Fields are Compulsory</p>
              </div>
              <!-- /.card-footer-->
            </div>
            <!-- /.card -->
          </div>
          <div class="col-6 col-md-6 col-sm-6">
            <!-- /.card -->
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      Loca_id: "",
      Loca_Name: "",
      Ad: "",
      Address1: "",
      Address2: "",
      PhoneNo: "",
      Fax: "",
      Email: "",
      StartDate: "",
    };
  },
  methods: {
    ...mapActions(["insertLocation"]),
    submit() {
      console.log("api call start");

      this.insertLocation({
        LocaCode: this.Loca_id,
        LocaName: this.Loca_Name,
        Ad: this.Ad,
        Ad1: this.Address1,
        Ad2: this.Address2,
        PhoneNo: this.PhoneNo,
        Fax: this.Fax,
        Email: this.Email,
        StartDate: this.StartDate,
      })
        .then((res) => {
          console.log(res);

          this.$alert(
            "Location Registered - " + res.LocaName,
            "Location Registration",
            "success"
          );
          this.$router.push("/home");
        })
        .catch((error) => {
          this.$alert(
            "Canot Insert Data! (Erorr:" + error.response.status + ")",
            "Company Registration",
            "info"
          );
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        });
    },
  },
  mounted() {
    console.log(localStorage.getItem("login"));

    if (
      localStorage.getItem("login") == "false" ||
      localStorage.getItem("token") == null
    ) {
      console.log(localStorage.getItem("login") + "ttttt");
      this.$router.push("/");
    }
  },
};
</script>

<style></style>
