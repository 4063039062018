<template>
<div>
    <router-view>
    </router-view>
</div>
</template>

<script>
// import Home from './components/landingPage.vue'
import Login from './components/Login.vue'

export default {
    data() {
        return {

        }
    },
    name: "App",
    components: {
        // HelloWorld
        // Home,
        Login,
    },

    created() {
        document.title = "NS Handy CRM ";
    }

};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Merienda&display=swap');
</style>
