<template>
<div>
    <div class="content-header">
        <div class="container-fluid">
            <div class="row mb-2">
                <div class="col-sm-12">
                    <h1 class="m-0 text-dark">Manual Points Update</h1>
                </div>
            </div>
        </div>
    </div>
    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-6 col-sm-6 col-md-6">
                    <!-- Default box -->
                    <div class="card">
                        <div class="card-header">
                            <h3 class="card-title">Enter Details</h3>
                        </div>
                        <div class="card-body">
                            <div class="col-md-12">
                                <div class="form-group">
                                    Customer Name
                                    <v-select placeholder="Search Customer" :options="$store.state.customer.customers" @search="getCustomer" v-model="customer" @change="readCustomer" @input="setCode" label="Name" :value="$store.state.selected" v-on:input="setCode"></v-select>
                                </div>
                            </div>
                            <form @submit.prevent="submit">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="row">
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    Customer Code
                                                    <input type="text" id="inputCusCode" class="form-control" v-model="CustCode" v-bind:disabled="true"/>
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    Card No
                                                    <input type="text" id="inputCardNo" class="form-control" v-model="CardNo" v-bind:disabled="true"/>
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    Mobile
                                                    <input type="text" id="inputMobile" class="form-control" v-model="Mobile" v-bind:disabled="true" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    Location
                                                    <select class="custom-select" v-model="Loca" id="inputLoca" required v-bind:disabled="flag">
                                                        <option v-for="l in locations" :key="l.LocaCode" :value="l.LocaCode">{{ l.LocaName }}</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    Bill Date
                                                    <input type="date" id="billDate" class="form-control" v-model="BillDate" @change="getInvoiceDetails" v-bind:disabled="flag" />
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    Unit
                                                    <select class="custom-select" v-model="Unit" id="inputUnit" @change="getInvoiceDetailsByUnit" required v-bind:disabled="flag">
                                                        <option v-for="u in InvUnit" :key="u" :value="u">{{ u }}</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    Report Id
                                                    <select class="custom-select" v-model="ReportId" id="inputReportId" @change="getInvoiceByreportId" required v-bind:disabled="flag">
                                                        <option v-for="r in InvReportId" :key="r" :value="r">{{ r }}</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    Invoice Time
                                                    <input type="time" id="billTate" class="form-control" v-model="BillTime" v-bind:disabled="flag" />
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    Invoice No
                                                    <input type="text" id="inputBillNo" class="form-control text-right" v-model="BillNo" v-bind:disabled="true" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            Amount
                                            <input type="text" id="inputAmount" class="form-control text-right" v-model="BillAmount" v-bind:disabled="true" />
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            Points
                                            <input type="text" id="inputpoints" class="form-control text-right" v-model="Epoint" v-bind:disabled="true" />
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            Point Type
                                            <div class="form-check">
                                                <input class="form-check-input" type="radio" v-model="pEarn" value="E" name="pointType" id="flexRadioDefault1" v-bind:disabled="flag" checked>
                                                <label class="form-check-label" for="flexRadioDefault1">
                                                    Earn
                                                </label>
                                            </div>
                                            <!-- <div class="form-check">
                                                <input class="form-check-input" type="radio" v-model="pRedeem" value="R" name="pointType" id="flexRadioDefault2" v-bind:disabled="true">
                                                <label class="form-check-label" for="flexRadioDefault2">
                                                    Redeem
                                                </label>
                                            </div> -->
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12  text-right">
                                    <div class="form-group">
                                        <button type="button" class="btn btn-primary float-right mr-2" @click="clear()">
                                            Clear
                                        </button>
                                    </div>
                                    <div class="form-group">
                                        <button type="submit" class="btn btn-success pull-right mr-2">
                                            Save
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <div class="col-6 col-sm-6 col-md-6">
                    <div class="card">
                        <div class="card-header">
                            <h3 class="card-title">Invoice Details</h3>
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-12 table-responsive" v-if="loaded">
                                    <table id="tblInv" class="table table-bordered table-striped" role="grid" aria-describedby="example1_info">
                                        <thead>
                                            <tr>
                                                <th>
                                                    Invoice Date
                                                </th>
                                                <th class="text-right">
                                                    Invoice No
                                                </th>
                                                <!-- <th class="text-right">
                                                    Unit
                                                </th>
                                                <th class="text-right">
                                                    Report Id
                                                </th> -->
                                                <th class="text-right">
                                                    Amount
                                                </th>
                                                <th class="text-center">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="ui in UnitInvDetails" :key="ui.Receipt_No" class="odd">
                                                <td class="dtr-control">{{ ui.Billdate }}</td>
                                                <td class="text-right">{{ ui.Receipt_No }}</td>
                                                <!-- <td class="text-right">{{ ui.Unit }}</td>
                                                <td class="text-right">{{ ui.ReportId }}</td> -->
                                                <td class="text-right">{{ ui.Amount.toFixed(2) }}</td>
                                                <td class="text-right">
                                                    <button @click="selectInvoice(ui.Receipt_No)" type="button" class="btn btn-info btn-xs mr-2">
                                                        <i class="fas fa-pencil-alt"> </i>
                                                    </button>
                                                </td>
                                            </tr>
                                        </tbody>
                                        <tfoot></tfoot>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- <section class="content">
        <div class="container-fluid">
            <div class="card">
                <div class="card-header">
                    <h3 class="card-title">Bill Details</h3>
                </div>
                <div class="card-body">
                    <div id="example1_wrapper" class="dataTables_wrapper dt-bootstrap4">
                        <div class="row">
                            <div class="col-sm-12 col-md-6">
                              
                            </div>
                            
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </section> -->
</div>
</template>

<script>
import {
    mapActions
} from "vuex";
import "vue-select/dist/vue-select.css";
import $ from "jquery";
export default {
    data() {
        return {
            CardNo: "",
            PostDate: "",
            CustCode: "",
            CustName: "",
            Ad1: "",
            Ad2: "",
            Ad3: "",
            NIC: "",
            Mobile: "",
            Epoint: 0.00,
            BillDate: "",
            BillTime: "",
            Unit: "",
            ReportId: "",
            BillNo: "",
            BillAmount: "0.00",
            customer: null,
            InvDetails: [],
            InvUnit: [],
            InvReportId: [],
            InvDetailsUnit: [],
            UnitInvDetails: [],
            pointRate: 0,
            locations: [],
            Loca: "",
            pRedeem: false,
            pEarn: "E",
            flag: true,
            loaded: false,
        };
    },
    methods: {
        ...mapActions(["insertpoint", "readCustomer", "fetchSelectedCustomerPoints"]),

        getCustomer(customer) {
            this.$store.commit("searchCustomer", customer);
        },
        submit() {
            var pointType = "";
            var selected = $("input[type='radio'][name='pointType']:checked");
            if (selected.length > 0) {
                pointType = selected.val();
            } else {
                this.toastMessage("Please Select Point Type", "info");
            }

            var data = {
                CardNo: this.CardNo,
                PostDate: this.PostDate,
                CustCode: this.CustCode,
                Mobile: this.Mobile,
                Epoint: this.Epoint,
                BillDate: this.BillDate,
                BillTime: this.BillTime,
                Unit: this.Unit,
                ReportId: this.ReportId,
                BillNo: this.BillNo,
                BillAmount: this.BillAmount,
                Type: pointType
            };
            if (this.CardNo == "" || this.CustCode == "") {
                this.toastMessage("Please Select Customer", "info");
                return;
            }

            this.$store
                .dispatch("points/updatePoints", data, {
                    root: true,
                })
                .then((data) => {
                    if (data.statusCode == 200) {
                        this.$loading(false);
                        // this.$alert("Manual Point Updated Document No: " + data.docNo, "Success", "success");
                        this.toastMessage("Manual Point Updated Document No: " + data.docNo, "success");
                    } else if (data.statusCode = 401) {

                    }
                })
                .catch((err) => {
                    console.log(err);
                    this.$alert(error.response.status, "Error Occured", "error");
                });
        },
        readCustomer(e) {
            this.readCustomer(e.Code)
                .then(() => {})
                .catch((error) => {
                    this.$alert(
                        "Canot Read Data! (Erorr:" + error.response.status + ")",
                        "Customer Registration",
                        "info"
                    );
                    console.log(error.response.status);
                });
        },
        clear() {
            this.CardNo = "",
                this.PostDate = "",
                this.CustCode = "",
                //this.CustName="",
                this.Ad1 = "",
                this.Ad2 = "",
                this.Ad3 = "",
                this.NIC = "",
                this.Mobile = "",
                this.Epoint = "",
                this.BillDate = "",
                this.BillTime = "",
                this.Unit = "",
                this.ReportId = "",
                this.BillNo = "",
                this.BillAmount = "";
            //this.customer= null
            this.flag = true;
            this.loaded=false;
            $("#tblInv").dataTable().fnDestroy();
        },
        searchCustomer(customer) {
            this.$store.commit("searchCustomer", customer);
        },
        setCode(e) {
            this.$loading(true);
            this.fetchSelectedCustomerPoints(e.Code)
                .then((res) => {
                    var data = this.$store.state.customer.customers[0];
                    console.log(data);
                    (this.CustCode = data.Code),
                    (this.CardNo = data.CardNo),
                    (this.NIC = data.NIC),
                    (this.Ad1 = data.Address),
                    (this.Mobile = data.Mobile),
                    (this.pointRate = data.PointsRate);
                    this.$loading(false);
                    this.flag = false;
                })
                .catch((error) => {
                    // this.$alert("Invalid login credentials !", "CRM Login", "info");
                    console.log(error);
                    this.$loading(false);
                    this.flag = true;
                });
        },
        getCategory(e) {
            this.$store.state.product.cats = [];
            this.category = [];
            this.$store.commit("setCategory", e.Code);
        },
        fetchLocations() {
            this.$loading(true);
            this.$store
                .dispatch("common/fetchLocations", null, {
                    root: true,
                })
                .then(() => {
                    this.locations = this.$store.state.common.locations;
                    $("#inputLoca").prop("selectedIndex", 1);

                });

            this.$loading(false);
        },
        getInvoiceDetails() {
            var payload = {
                BillDate: this.BillDate,
                Loca: this.Loca,
            };
            console.log(this.Loca)
            this.$loading(true);
            this.$store
                .dispatch("points/fetchInvoices", payload, {
                    root: true,
                })
                .then((data) => {
                    // console.log(data)
                    if (data.strRturnRes) {
                        this.InvDetails = this.$store.state.points.invoices;
                        var arr = [];
                        this.InvDetails.forEach((e) => {
                            arr.push(e.Unit);
                        });
                        this.InvUnit = arr.filter(function (item, pos, self) {
                            return self.indexOf(item) == pos;
                        });
                    } else {
                        console.log("Show Toast");
                    }

                    this.$loading(false);
                });
        },
        getInvoiceDetailsByUnit() {
            var UnitInvoice = [];
            var u = this.Unit;
            var rptId = [];
            UnitInvoice = this.InvDetails.filter(function (e) {
                return e.Unit == u;
            });
            console.log(UnitInvoice);
            rptId = [];
            UnitInvoice.forEach((e) => {
                rptId.push(e.ReportId);
            });
            this.InvReportId = rptId.filter(function (item, pos, self) {
                return self.indexOf(item) == pos;
            });
        },
        getInvoiceByreportId() {
            var payload = {
                BillDate: this.BillDate,
                Loca: this.Loca,
                Unit: this.Unit,
                ReportId: this.ReportId,
            };
            $("#tblInv").dataTable().fnDestroy();
            this.$loading(true);
            this.$store
                .dispatch("points/fetchInvoices", payload, {
                    root: true,
                })
                .then((data) => {
                    this.UnitInvDetails = [];
                    this.UnitInvDetails = this.$store.state.points.invoices;

                    setTimeout(() => {
                        $("#tblInv").DataTable({
                            pageLength: 6,
                            processing: true,
                            autoWidth: true,

                        });
                    });

                });
            this.loaded = true;
            this.$loading(false);
        },
        selectInvoice(id) {
            this.$loading(true);
            var obj = this.UnitInvDetails.filter((x) => x.Receipt_No === id);
            if (obj.length > 0) {
                this.BillAmount = obj[0].Amount.toFixed(2);
                // this.BillTime = obj[0].GroupName;
                this.BillNo = obj[0].Receipt_No;
                this.Epoint = ((this.BillAmount / 100) * this.pointRate).toFixed(2);
            }
            this.$loading(false);
        },
        toastMessage(text, type) {
            this.$swal.fire({
                position: "center",
                icon: type,
                title: text,
                showConfirmButton: false,
                timer: 1500,
            });
        },
    },
    created() {
        this.fetchLocations();
    },
    computed: {
        a(e) {
            this.CustCode = e.Code;
        },
    },
    mounted() {
        this.$loading(true);

        if (localStorage.getItem("login") == "false" || localStorage.getItem("token") == null) {
            this.$router.push("/");
        }
        this.$loading(false);
    },
};
</script>

<style></style>
