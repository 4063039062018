import axios from "axios";

const state = {
  token: localStorage.getItem("token") || null,
  user: null,
  login: false,
};

const getters = {
  isAuthenticated: (state) => state.token !== null,
  user: (state) => state.user,
};

const mutations = {
  SET_TOKEN(state, token) {
    state.token = token;
  },
  SET_USER(state, user) {
    state.user = user;
  },
  SET_LOGSTATE(state, st) {
    state.login = st;
  },
};

const actions = {
  login({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          "CRM/authentication",

          {
            username: payload.user_name,
            password: payload.password,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((data) => {
          commit("SET_USER", data.data.FirstName);
          localStorage.setItem("token", data.data.access_token);
          localStorage.setItem("user", data.data.FirstName);
          localStorage.setItem("login", true);

          console.log(data.data);
          resolve(data);
        })
        .catch((err) => {
          // localStorage.removeItem('token')
        //   console.log(err)
          localStorage.setItem("login", false);
          reject(err);
        });
    });
  },
};

export default { state, getters, mutations, actions };
