import axios from "axios";

const options = {
  headers: {
    "Content-Type": "application/json",
    Authorization: "bearer " + localStorage.getItem("token") || null,
  },
};
const namespaced = true;
const state = {
  dashCardData: [],
  dashMonthRecapData:[]
};

const getters = {
  dashCardData: (state) => state.dashCardData,
  dashMonthRecapData: (state) => state.dashMonthRecapData,
};
namespaced;
const mutations = {
  SET_DASHBOARD_CARD_DATA(state, data) {
    state.dashCardData = data;
  },
  SET_DASHBOARD_CHART_DATA(state, data) {
    state.dashCardData = data;
  },
};

const actions = {
  fetchDashboardCardData({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("dashboard/card", options)
        .then(({ data }) => {
          commit("SET_DASHBOARD_CARD_DATA", data.data);
          resolve(data.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchDashMonthRecapData({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("dashboard/MonthlySalesRecap/1", options)
        .then(({ data }) => {
          commit("SET_DASHBOARD_CHART_DATA", data);
          //console.log(data)
          // console.log(data.sales)
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

export default { namespaced, state, getters, mutations, actions };
