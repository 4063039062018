import axios from 'axios';


const options = {
    headers: {
        "Content-Type": "application/json",
        "Authorization": "bearer " + localStorage.getItem('token') || null
    }
}

const state = {
  
};

const getters = {
 
};

const mutations = {
  
};


const actions = {

    insertLocation({commit}, payload) {
        return new Promise((resolve, reject) => {
            console.log("ttttttttttttttttttttttttttttttttttttt", payload.LocaName);
            axios.post(
                "CRM/CreateLoca",
                {
                    LocaCode :payload.LocaCode,
                    LocaName :payload.LocaName,
                    Ad:payload.Ad,
                    Ad1 :payload.Ad1,
                    Ad2 :payload.Ad2,
                    PhoneNo :payload.PhoneNo,
                    Fax :payload.Fax,
                    Email :payload.Email,
                    StartDate :payload.StartDate,
                    InsertUser :localStorage.getItem('user')
        
                },

                options,

            ).then(({ data }) => {

                console.log(data)
                resolve(data);
            }).catch(err => {
                // localStorage.removeItem('token')
                reject(err)
            });
        })

    }


};

export default { state, getters, mutations, actions };