import Vue from 'vue';
import Vuex from 'vuex';

import common from './modules/common'
import users from './modules/users';
import customer from './modules/customer'
import location from './modules/location'
import points   from './modules/points'
import customerReg from './modules/customerReg';
import smsStore from './modules/smsStore';
import settings from './modules/settings/settingsStore';
import rptCustomerDetails from './modules/reports/customerDetails'
import rptCustomerStatement from './modules/reports/customerStatement'
import dashboard from './modules/dashboard/dashCard'
Vue.use(Vuex);

const store = new Vuex.Store({
    
    modules: {
        common,
        users,
        customer,
        location,
        points,
        customerReg,
        smsStore,
        settings,
        rptCustomerDetails,
        rptCustomerStatement,
        dashboard
    }
});

export default store;