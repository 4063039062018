<template>
  <div class="sidenav">
    <div class="login-main-text">
      <center>
         <img alt="Vue logo" src="../assets/logo.png"> 
      <h2>
        Welcome to <br />
        NS Handy CRM
      </h2>
     
          </center>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.sidenav {
  height: 100%;
  background-color: rgb(157, 180, 187);
  overflow-x: hidden;
  padding-top: 20px;
}

@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px;
  }
}
@media screen and (min-width: 768px) {
.sidenav {
  width: 40%;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
}
}
.login-main-text {
  margin-top: 10%;
  padding: 60px;
  color: #fff;
}

.login-main-text h2 {
  font-weight: 300;
}
</style>