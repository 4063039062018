import axios from "axios";

const options = {
  headers: {
    "Content-Type": "application/json",
    Authorization: "bearer " + localStorage.getItem("token") || null,
  },
};

const state = {
  state: {
    customers: [],
    customersList: [],
    selectedCustomer: [],
  },
};

const getters = {};

const actions = {
  async fetchCustomers({ commit }) {
    await axios
      .get("CRM/Dataret/AllCust", options)
      .then((r) => r.data)
      .then((data) => {
        commit("UPDATE_CUSTOMER", data.ResData.DataRet);
      })
      .catch((err) => {
        console.log(err);
      });
  },

  fetchOneCustomer({ commit }, customerCode) {
    axios
      .get("CRM/getCustomer" + customerCode)
      .then((data) => {
        commit("UPDATE_CUSTOMER", data);
      })
      .catch((err) => {
        console.log(err);
      });
  },

  fetchSelectedCustomer({ commit }, customerCode) {
    return new Promise((resolve, reject) => {
      axios
        .get("CRM/Dataret/Cus/" + customerCode, options)
        .then((data) => {
          state.customers = data.data.ResData.searchRes;
          //console.log(data.data.ResData.searchRes)
          commit("UPDATE_SELECTED_CUSTOMER", data.data.ResData.searchRes);
          resolve(data.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  getCustomerFilter({ commit }, customer) {
    console.log(state);
    return new Promise((resolve, reject) => {
      axios
      .get("CRM/SearchCus/" + customer, options)
      .then((r) => r.data)
      .then((data) => {

        commit("UPDATE_CUSTOMER",data.ResData.searchRes);
        resolve(data)
        // state.customers = data.ResData.searchRes;
        //setCust(sta)
      })
      .catch((err) => {
        reject(err)
        console.log(err);
      });
    });



    
  },
};

const mutations = {
  UPDATE_CUSTOMER(state, payload) {
    state.customersList = payload;
    state.customers=payload;
  },
  UPDATE_SELECTED_CUSTOMER(state, payload) {
    state.selectedCustomer = payload;
  },

  setActivePerson(state, person) {
    state.activePerson = person;
  },

};

export default { state, getters, mutations, actions };
