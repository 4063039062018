import axios from "axios";
const namespaced = true;
const options = {
  headers: {
    "Content-Type": "application/json",
    Authorization: "bearer " + localStorage.getItem("token") || null,
  },
};

const state = {
  invoices: [],
};

const getters = {
  invoices: (state) => state.invoices,
};

const mutations = {
  SET_INVOICES(state, data) {
    state.invoices = [];
    state.invoices = data;
  },
  SET_POINTUPDATE(state, data) {},
};

const actions = {
  insertpoint({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          "CRM/insertpoint",
          {
            CardNo: payload.CardNo,
            PostDate: payload.PostDate,
            CustCode: payload.CustCode,
            CustName: payload.CustName,
            Ad1: payload.Ad1,
            Ad2: payload.Ad2,
            Ad3: payload.Ad3,
            NIC: payload.NIC,
            Mobile: payload.Mobile,
            Epoint: payload.Epoint,
            BillDate: payload.BillDate,
            BillTime: payload.BillTime,
            Unit: payload.Unit,
            ReportId: payload.ReportId,
            BillNo: payload.BillNo,
            BillAmount: payload.BillAmount,
            UserName: localStorage.getItem("user"),
          },
          options
        )
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  updatePoints({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("CRM/PointUpdate", payload, options)
        .then(({ data }) => {
          commit("SET_POINTUPDATE", data.data);
          resolve(data);
        })
        .then((err) => {
          reject(err);
        });
    });
  },

  fetchInvoices({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("CRM/getInvoices", payload, options)
        .then(({ data }) => {
          // console.log(data.data)
          commit("SET_INVOICES", data.data);

          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

export default { namespaced, state, getters, mutations, actions };
