<template>
    <div>
        <div class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-md-12">
                        <h1>Customer</h1>
                    </div>
                </div>
            </div>
        </div>

        <section class="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-12">
                        <!-- Default box -->
                        <div class="card card-primary">
                            <div class="card-header">
                                <h3 class="card-title">Enter Details</h3>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            Search Customer
                                            <v-select class="style-chooser" placeholder="Search Customer"
                                                :options="customers" @search="searchCustomer" v-model="customer"
                                                label="Name" :value="$store.state.selected" @input="onSelectCustomer">
                                            </v-select>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" v-model="custState" />
                                            <label class="form-check-label">Non Approved</label>
                                        </div>
                                    </div>
                                </div>

                                <form ref="custReg" @submit.prevent="submit">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <div class="row">
                                                    <div class="col-md-2">
                                                        Code
                                                        <input type="text" id="code" class="form-control" v-model="Code"
                                                            disabled />
                                                    </div>
                                                    <div class="col-md-2">
                                                        Title
                                                        <select class="form-control" v-model="Title" required>
                                                            <option>MR.</option>
                                                            <option>MS.</option>
                                                            <option>MRS.</option>
                                                            <option>HON.</option>
                                                        </select>
                                                    </div>
                                                    <div class="col-md-4">
                                                        First Name
                                                        <input type="text" id="FirstName" class="form-control"
                                                            v-model="FirstName" required />
                                                    </div>
                                                    <div class="col-md-4">
                                                        <!-- <label for="inputName">Product Name</label> -->
                                                        Last Name
                                                        <input type="text" id="LastName" class="form-control"
                                                            v-model="LastName" required />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                Address
                                                <textarea type="text" id="Address" class="form-control"
                                                    v-model="Address" required></textarea>
                                            </div>
                                            <div class="row">

                                                <!-- <div class="col-md-1"></div> -->
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <!-- <label for="inputName">Product Name</label> -->
                                                        Email
                                                        <input type="email" id="Mobile" class="form-control"
                                                            v-model="email" />
                                                    </div>
                                                </div>

                                                <div class="col-md-3">
                                                    <div class="form-group">
                                                        <!-- <label for="inputName">Product Name</label> -->
                                                        Mobile
                                                        <input type="number" id="Mobile" class="form-control"
                                                            v-model="Mobile" required />
                                                    </div>
                                                </div>
                                                <div class="col-md-3">
                                                    <div class="form-group">
                                                        <!-- <label for="inputName">Product Name</label> -->
                                                        NIC
                                                        <input type="text" id="NIC" class="form-control" v-model="NIC"
                                                            required />
                                                    </div>
                                                </div>

                                            </div>
                                            <div class="row">
                                                <div class="col-md-3">
                                                    <div class="form-group">
                                                        <!-- <label for="inputName">Product Name</label> -->
                                                        Birthday
                                                        <input type="date" id="datefrom" class="form-control"
                                                            v-model="Birthday" />
                                                    </div>
                                                </div>
                                                <div class="col-md-2">
                                                    Gender
                                                    <br>
                                                    <div class="form-check form-check-inline">
                                                        <input class="form-check-input" type="radio" value="M"
                                                            name="radio1" v-model="Gender" />
                                                        <label class="form-check-label">Male</label>
                                                    </div>
                                                    <div class="form-check form-check-inline">
                                                        <input class="form-check-input" type="radio" name="radio1"
                                                            value="F" v-model="Gender" :checked="checked" />
                                                        <label class="form-check-label">Female</label>
                                                    </div>

                                                </div>
                                                <div class="col-md-2">
                                                    Approval
                                                    <div class="form-check">
                                                        <input class="form-check-input" type="checkbox"
                                                            v-model="isApproved" />
                                                        <label class="form-check-label">Approve</label>
                                                    </div>
                                                </div>

                                            </div>
                                            <!-- <div class="row">
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    
                                                    Mobile
                                                    <input type="text" id="Mobile" class="form-control" v-model="Mobile" required />
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    
                                                    NIC
                                                    <input type="text" id="NIC" class="form-control" v-model="NIC" required />
                                                </div>
                                            </div>
                                        </div> -->
                                        </div>
                                        <div class="col-md-6">

                                            <div class="row">
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        Card no
                                                        <input type="text" id="CardNo" class="form-control"
                                                            v-model="CardNo" required />
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        Card Issue Date
                                                        <input type="date" id="issuedate" class="form-control"
                                                            v-model="issuedate" required />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        Customer Group
                                                        <select class="custom-select" v-model="CustomerGroup"
                                                            id="custGroup" required>
                                                            <option v-for="optionCGs in optionCG" :key="optionCGs.id"
                                                                :value="optionCGs.id">{{ optionCGs.options }}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <!-- <label for="inputName">Product Name</label> -->
                                                        Religion
                                                        <select class="custom-select" v-model="Religion" required>
                                                            <option v-for="optionRELs in optionREL"
                                                                :value="optionRELs.id" v-bind:key="optionRELs.id">
                                                                {{ optionRELs.options }}
                                                            </option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <!-- <label for="inputName">Product Name</label> -->
                                                        Location
                                                        <select class="custom-select" v-model="compLoca" required>
                                                            <option v-for="lc in locations" :value="lc.LocaCode"
                                                                :key="lc.LocaCode">
                                                                {{ lc.LocaName }}
                                                            </option>

                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    Festival
                                                    <div class="form-group">
                                                        <div class="row">

                                                            <div class="col-md-6">
                                                                <div class="form-check">
                                                                    <input class="form-check-input" type="checkbox"
                                                                        v-model="New_Year" />
                                                                    <label class="form-check-label">New Year</label>
                                                                </div>

                                                                <div class="form-check">
                                                                    <input class="form-check-input" type="checkbox"
                                                                        v-model="Vesak" />
                                                                    <label class="form-check-label">Vesak</label>
                                                                </div>

                                                                <div class="form-check">
                                                                    <input class="form-check-input" type="checkbox"
                                                                        v-model="X_Mas" />
                                                                    <label class="form-check-label">X' Mas</label>
                                                                </div>

                                                                <div class="form-check">
                                                                    <input class="form-check-input" type="checkbox"
                                                                        v-model="Thaipongal" />
                                                                    <label class="form-check-label">Thaipongal</label>
                                                                </div>

                                                                <div class="form-check">
                                                                    <input class="form-check-input" type="checkbox"
                                                                        v-model="Deepawali" />
                                                                    <label class="form-check-label">Deepawali</label>
                                                                </div>
                                                            </div>

                                                            <div class="col-6">
                                                                <div class="form-check">
                                                                    <input class="form-check-input" type="checkbox"
                                                                        v-model="Ramazan" />
                                                                    <label class="form-check-label">Ramazan</label>
                                                                </div>

                                                                <div class="form-check">
                                                                    <input class="form-check-input" type="checkbox"
                                                                        v-model="WomenDay" />
                                                                    <label class="form-check-label">Women Day</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="form-group">
                                            <input type="submit" value="Save"
                                                class="btn btn-success float-right mr-2" />
                                        </div>
                                        <div class="form-group">
                                            <input type="button" value="Clear" @click="clearForm()"
                                                class="btn btn-primary float-right mr-2" />
                                        </div>

                                    </div>
                                </form>

                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="col-md-12">
                                            <div class="card">
                                                <div class="card-header">
                                                    <h3 class="card-title">Customers Details</h3>
                                                    <!-- <button class="btn btn-success" @click="showModal = true">Add Customer</button> -->
                                                </div>
                                                <!-- /.card-header -->
                                                <div class="card-body table-responsive">
                                                    <table id="customerDetails" class="table table-bordered "
                                                        width="100%">
                                                        <thead>
                                                            <tr role="row">
                                                                <th>Customer Code</th>
                                                                <th>Card No</th>
                                                                <th>Customer Name</th>
                                                                <th>Mobile No</th>
                                                                <th>Email</th>
                                                                <!-- <th>Address</th> -->
                                                                <th>Card Issued Date</th>
                                                                <th>Customer Group</th>
                                                                <th class="text-right">Balance Points</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr v-for="c in customersList" :key="c.Code">
                                                                <td>{{ c.Code }}</td>
                                                                <td>{{ c.CardNo }}</td>
                                                                <td>{{ c.Name.toUpperCase() }}</td>
                                                                <td>{{ c.Mobile }}</td>
                                                                <td>{{c.Email}}</td>
                                                                <!-- <td>{{ c.Address.toUpperCase() }}</td> -->

                                                                <td>{{ c.issuedate }}</td>
                                                                <td>{{ c.options.toUpperCase() }}</td>
                                                                <td class="text-right">{{ c.PointBalance }}</td>
                                                            </tr>
                                                        </tbody>
                                                        <tfoot></tfoot>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- /.card-body -->
                            <div class="card-footer">
                                <p>* Marked Fields are Compulsory</p>
                            </div>
                            <!-- /.card-footer-->
                        </div>
                        <!-- /.card -->

                    </div>

                    <!-- <div v-if="showModal">
                    <transition name="modal">
                        <div class="modal-mask">
                            <div class="modal-wrapper">
                                <div class="modal-dialog modal-lg" role="document">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <h5 class="modal-title">Modal title</h5>
                                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                <span aria-hidden="true" @click="showModal = false">&times;</span>
                                            </button>
                                        </div>
                                        <div class="modal-body">
                                            <p>Modal body text goes here.</p>
                                        </div>
                                        <div class="modal-footer">
                                            <button type="button" class="btn btn-secondary" @click="showModal = false">Close</button>
                                            <button type="button" class="btn btn-primary">Save changes</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </transition>
                </div> -->
                </div>
            </div>
        </section>
    </div>
</template>

<script scoped>
import {
    mapActions,
    mapGetters
} from "vuex";
import moment from 'moment';
export default {
    data() {
        return {
            Code: "",
            Title: "",
            FirstName: "",
            LastName: "",
            Address: "",
            Mobile: "",
            NIC: "",
            CardNo: "",
            issuedate: "",
            CustomerGroup: "",
            Religion: "",
            Gender: "M",
            Birthday: "",
            New_Year: false,
            Vesak: false,
            X_Mas: false,
            Thaipongal: false,
            Deepawali: false,
            Ramazan: false,
            WomenDay: false,
            compLoca: null,
            customer: null,
            customers: [],
            customersList: [],
            selectedCust: [],
            selCust: null,
            checked: null,
            showModal: false,
            locations: [],
            email: "",
            isApproved: false,
            custState: false
        };
    },
    computed: {
        ...mapGetters(["optionCG", "optionREL"]),
    },
    methods: {
        ...mapActions([
            "getCusGroup",
            "getRelegions",
            "insertCustomer",
            "fetchSelectedCustomer",
            "fetchCustomers",
            "fetchNonApprovedCustomers"
        ]),

        searchCustomer(customer) {
            if (this.custState == true) {
                this.fetchNonApprovedCustomers(customer)
                    .then(() => {
                        this.customers = this.$store.state.customerReg.customers;
                    })
                    .catch((error) => {
                        console.log(error);
                    });

            } else {
                // this.$store.commit("getCustomerFilter", customer);
                this.$store.dispatch("getCustomerFilter", customer).then(() => {
                    this.customers = this.$store.state.customerReg.customers;
                });
            }


        },

        submit() {
            console.log(this.Mobile.length)

            if (this.Mobile.length > 10 || this.Mobile.length < 10) {
                this.toastMessage("Check Mobile No", "info");
                return;
            }

            this.$confirm(
                "Are you sure you want to Save Customer?",
                "Customer Registration",
                "question"
            ).then(() => {

                this.insertCustomer({
                    Code: this.Code,
                    Title: this.Title.toUpperCase(),
                    FirstName: this.FirstName.toUpperCase(),
                    LastName: this.LastName.toUpperCase(),
                    Address1: this.Address.toUpperCase(),
                    Mobile: this.Mobile,
                    NIC: this.NIC,
                    CardNo: this.CardNo.toUpperCase(),
                    issuedate: moment(this.issuedate).format('DD/MM/YYYY'),
                    CustomerGroup: this.CustomerGroup,
                    Religion: this.Religion,
                    Gender: this.Gender,
                    Birthday: this.Birthday,
                    New_Year: this.New_Year,
                    Vesak: this.Vesak,
                    X_Mas: this.X_Mas,
                    Thaipongal: this.Thaipongal,
                    Deepawali: this.Deepawali,
                    Ramazan: this.Ramazan,
                    WomenDay: this.WomenDay,
                    loca: this.compLoca,
                    email: this.email,
                    IsApproved: this.isApproved
                }).then((res) => {
                        console.log(res);
                        if (res.Code == 200) {
                            this.$alert(
                                "Customer Registered as " + res.Cust_Code + "-" + res.Cust_Name,
                                "Customer Registration",
                                "success"
                            );
                            this.$refs.custReg.reset();
                        } else if (res.Code == 500) {
                            this.$alert(
                                "Error Occuerd: " + res.Info,
                                "Customer Registration",
                                "info"
                            );
                        } else {
                            this.$alert(
                                res.Info,
                                "Customer Registration",
                                "info"
                            );
                        }
                        // This will clear that form
                    })
                    .catch((error) => {
                        this.$alert(
                            "Cannot Insert Data! (Error: " + error + ")",
                            "Customer Registration",
                            "info"
                        );
                    });
            });
        },

        metOne() {
            this.$loading(true);
            this.getRelegions()
                .then(() => { })
                .catch((error) => {
                    alert(error);
                    console.log(error);
                });
            this.$loading(false);
        },

        metTwo() {
            this.getCusGroup()
                .then(() => { })
                .catch((error) => {
                    alert(error);
                    console.log(error);
                });
        },

        onSelectCustomer(event) {
            this.$loading(true);
            this.fetchSelectedCustomer(event.Code)
                .then(() => {
                    var selectedCust = this.$store.state.customerReg.selectedCustomer[0];
                    this.Code = selectedCust.Code
                    this.CustomerGroup = this.selCust;
                    this.Title = selectedCust.Title.toUpperCase().trim();
                    this.FirstName = selectedCust.FirstName;
                    this.LastName = selectedCust.LastName;
                    this.Address = selectedCust.Address;
                    this.Mobile = selectedCust.Mobile;
                    this.NIC = selectedCust.NIC;
                    this.CardNo = selectedCust.CardNo;
                    this.issuedate = moment(selectedCust.issuedate).format('YYYY-MM-DD');
                    this.CustomerGroup = selectedCust.CustomerGroup.trim();
                    this.Religion = selectedCust.Religion.trim();
                    this.Gender = selectedCust.Gender.trim();
                    this.Birthday = selectedCust.Birthday;
                    this.New_Year = selectedCust.New_Year;
                    this.Vesak = selectedCust.Vesak;
                    this.X_Mas = selectedCust.X_Mas;
                    this.Thaipongal = selectedCust.Thaipongal;
                    this.Deepawali = selectedCust.Deepawali;
                    this.Ramazan = selectedCust.Ramazan;
                    this.WomenDay = selectedCust.WomenDay;
                    this.compLoca = selectedCust.Loca;
                    this.email = selectedCust.email;
                    this.isApproved = selectedCust.IsApproved;
                    console.log(moment(selectedCust.issuedate).format('YYYY-MM-DD'))
                })
                .catch((error) => {
                    console.log(error);
                });
            this.$loading(false);
        },

        fetchLocation() {
            this.$loading(true);
            this.$store.dispatch("common/fetchLocations", null, {
                root: true
            }).then(() => {
                this.locations = this.$store.state.common.locations;
            });
            this.$loading(false);
        },
        toastMessage(text, type) {
            this.$swal.fire({
                position: "center",
                icon: type,
                title: text,
                showConfirmButton: false,
                timer: 1500,
            });
        },

        clearForm(){
           // this.$refs.custReg.reset();
                    this.Code = ""
                    this.CustomerGroup = "";
                    this.Title = "";
                    this.FirstName ="";
                    this.LastName = "";
                    this.Address = "";
                    this.Mobile = "";
                    this.NIC = "";
                    this.CardNo = "";
                    this.issuedate = "";
                    this.CustomerGroup = "";
                    this.Religion = "";
                    this.Gender = "M";
                    this.Birthday = "";
                    this.New_Year = false;
                    this.Vesak = false;
                    this.X_Mas = false;
                    this.Thaipongal = false;
                    this.Deepawali = false;
                    this.Ramazan = false;
                    this.WomenDay = false;
                    this.compLoca = false;
                    this.email = "";
                    this.isApproved = false;
        }
    },
    created() {
        this.metOne();
        this.metTwo();

        this.fetchLocation();

        this.$store.dispatch("common/fetchCustomers", null, {
            root: true
        }).then(() => {
            this.customersList = this.$store.state.common.customersData;
            // console.log(this.$store.state.common.customersData)
        });
    },
    mounted() {
        if (localStorage.getItem("login") == "false" || localStorage.getItem("token") == null) {
            this.$router.push("/");
        }
    },
};
</script>

<style>
.center_div {
    margin: 0 auto;
    width: 80%;
    /* value of your choice which suits your alignment */
}

.modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    display: table;
    transition: opacity .3s ease;
}

.modal-wrapper {
    display: table-cell;
    vertical-align: middle;
}

.v-select {
    /* background-color: green!important;  */
    color: #ffffff !important;
}

/* 
.style-chooser .vs__search::placeholder,
.style-chooser .vs__dropdown-toggle, 
.style-chooser .vs__dropdown-menu {
    background: #343A40;
    border-color: #fff;
    border-width: "1px";
    color: #fff;
    text-transform: uppercase;
    font-variant: small-caps;
}

.style-chooser .vs__clear,
.style-chooser .vs__open-indicator {
    fill: #fff;
} */
</style>
