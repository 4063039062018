import VueRouter from 'vue-router'

import UserLogin from '../components/Login'
import LandingPage from '../components/landingPage'
import CustReg from '../components/CustomerReg'
import ComReg from '../components/CompanyProfile'
import PontAdd from '../components/AddPoints'
import SendSMS from '../components/SendSMS'
import Settings from '../components/admin/Settings'
import CustomerDetails from '../components/reports/customerDetailsInq'
import CustomerBalanceReport from '../components/reports/customerBalance'
import CustomerStatementReport from '../components/reports/customerStatement'
import Dashboard from '../components/Home/Dashboard'
const router = new VueRouter({
    mode: "history",
    routes: [

        {
            path: '/',
            component: UserLogin,
            name: 'login',


        },

        {
            path: '/home',
            component: LandingPage,
            name: 'Admin',
            children: [
                {
                    path: '/dashboard',
                    component: Dashboard,
                    name: 'Dashboard',
                },
                {
                    path: '/regcustomer',
                    component: CustReg
                },
                {
                    path: '/company',
                    component: ComReg
                },
                {
                    path: '/points',
                    component: PontAdd
                },
                {
                    path: '/sendsms',
                    component: SendSMS
                },
                {
                    path: '/inqCustDetails',
                    component: CustomerDetails
                },
                {
                    path: '/customerBalance',
                    component: CustomerBalanceReport
                },
                {
                    path: '/customerStatement',
                    component: CustomerStatementReport
                },
                {
                    path: '/settings',
                    component: Settings
                }
            ]
        },
        // {
        //     path:'*',
        //     component:'',
        // }
        // { path: '/login', component: Login },
        // { path: '/about', component: About }
    ]
})


export default router;